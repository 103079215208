


import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

import friends from '../images/friends.png';

const Success = () => (
  <Layout>
    <p>Thank you for contacting us!</p>
    <Link to="/">Go back to the homepage</Link>
    <img className="image fit" src={friends} alt="Friends of CRB" />
  </Layout>
)

export default Success;